import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { HouseType, HouseTypeModification } from '../../dtos/house-type';
import { GlobalService } from '../../services/global.service';
import { HouseTypeService } from './services/house-type.service';
import { IJob } from '../../dtos/job';
import { JobService } from '../../services/job.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { GridService } from '../../services/grid.service';
import { MaintenanceHelperService } from '../maintenance-helper.service';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { Recipe } from '../../dtos/recipe';
import { NotificationService } from '../../services/notification.service';
import { HouseTypeApiService } from './services/house-type.api.service';
import { HouseOptionsGridComponent } from '../../option-lists/house-options-grid/house-options-grid.component';
import DataSource from 'devextreme/data/data_source';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SetCommissionItemComponent } from './set-commission-item/set-commission-item.component';
import { GridsEditMode } from 'devextreme/common/grids';
import { ConfigurationEnum } from '../../dtos/configuration-enum';


@Component({
  selector: 'js-house-types',
  templateUrl: './house-types.component.html',
  styleUrls: ['./house-types.component.scss']
})
export class HouseTypesComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  addModSub = new Subject<void>();
  addTypeSub = new Subject<void>();

  jobs: IJob[];
  subscriptions: Subscription[] = [];
  dataSource: any = {};
  modMode = false;
  titleText: string;
  innerWidth: number;
  statusFilterMode = 'all';
  query = '';
  cssSmlWidth = 500;
  statuses: string[];
  currentFilter = 'Active Only';
  loading = true;
  recipes: Recipe[];
  dropDownOptions: object;
  recipeData: DataSource;
  updatePricesPopup: boolean;
  reCostingDate: any;
  gridHeight: number;
  houseTypeUseCommissionFields: boolean;
  costToCompanyOptions: object;
  editMode: GridsEditMode = 'row';
  calcSellingPricesMode: boolean;
  roundSellingPricesTo: number;
  upSellingPricesBy: number;
  upSellingPricesByPercent: number;
  houseTypeIds: number[] = [];
  lessAmount = 0;
  waitingClear = false;
  increaseSellingPricesMode: boolean;

  constructor(
    private globalService: GlobalService,
    private houseTypeService: HouseTypeService,
    public houseTypeApiService: HouseTypeApiService,
    private jobService: JobService,
    public gridService: GridService,
    private estimatingService: EstimatingService,
    private helper: MaintenanceHelperService,
    private notiService: NotificationService,
    private modalService: NgbModal) {
    this.setupTypes();
    this.setHouseMasterCellValue = this.setHouseMasterCellValue.bind(this);
    this.setHouseSpecificationCellValue = this.setHouseSpecificationCellValue.bind(this);
    this.calculateHouseArea = this.calculateHouseArea.bind(this);
    this.calculateBlockFrontage = this.calculateBlockFrontage.bind(this);
    this.setCostPlusPercentage = this.setCostPlusPercentage.bind(this);
    this.setRecipeCost = this.setRecipeCost.bind(this);
    this.setCostPlusAmount = this.setCostPlusAmount.bind(this);
    this.setPercentageMarkUp = this.setPercentageMarkUp.bind(this);
    this.cancelActualSellingPrices = this.cancelActualSellingPrices.bind(this);

    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe(width => {
        this.innerWidth = width;
        this.gridHeight = window.innerHeight - 110;
      }),
      this.jobService.getJobsForCompany().subscribe(jobs => {
        this.jobs = jobs;
      }),
      this.houseTypeApiService.getHouseMasters(true).subscribe(res => {
        // this.houseMasters = res;
      }),
      this.houseTypeApiService.getHouseSpecifications(true).subscribe(res => {
        // this.houseSpecifications = res;
      })
    );

    this.dataSource = this.houseTypeService.getHouseTypeDataSource();
    this.dropDownOptions = { width: 800, minHeight: 500 };
  }

  ngOnInit() {
    this.innerWidth = this.globalService.innerWidth;
    this.gridHeight = window.innerHeight - 110;

    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.HouseTypeUseCommissionFields) === 1) {
      this.houseTypeUseCommissionFields = true;
    }

    this.costToCompanyOptions = {
      readOnly: this.houseTypeUseCommissionFields,
      showClearButton: !this.houseTypeUseCommissionFields
    }
    this.getRecipes();
  }

  getRecipes() {
    // get the recipes for the list
    this.subscriptions.push(
      this.estimatingService.getRecipeWithSellingRates(true)
        .subscribe(
          recipes => {
            this.recipes = recipes;

            this.recipeData = new DataSource({
              key: 'id',
              loadMode: 'raw',
              load: () => this.recipes
            });

            this.loading = false;
          },
          err => {
            this.notiService.notify(err);
            this.loading = false;
          })
    );
  }

  addType() {
    if (this.modMode) {
      this.addModSub.next();
    } else {
      this.addTypeSub.next();
    }
  }

  /* refresh house types + jobs */
  refresh() {
    this.houseTypeService.clearCaches();
    this.grid.instance.refresh();
    this.jobService.getJobsForCompany().subscribe(jobs => {
      this.jobs = jobs;
    });
  }

  filterTypes = (type: HouseType | HouseTypeModification) => {
    if (this.statusFilterMode === 'all') {
      if (this.query === '') {
        return true;
      }
      return (type.description.toLowerCase().indexOf(this.query) > -1);
    } else {
      let activeBool: Boolean;
      this.statusFilterMode === 'active' ? activeBool = true : activeBool = false;
      if (this.query === '' && type.isActive === activeBool) {
        return true;
      }
      return (type.description.toLowerCase().indexOf(this.query) > -1) && type.isActive === activeBool;
    }
  }

  toggleMode() {
    if (this.modMode) {
      this.setupTypes();
      this.helper.houseTypeModMode.next(false);
    } else {
      this.setupMods();
      this.helper.houseTypeModMode.next(true);
    }
  }

  setupTypes() {
    this.titleText = '';
    this.modMode = false;
    this.dataSource = this.houseTypeService.getHouseTypeDataSource();
    this.statuses = ['Active Only', 'All', 'Not Active'];
  }

  setupMods() {
    this.titleText = 'House Type Modifications';
    this.modMode = true;
    this.dataSource = this.houseTypeService.getHouseModDataSource();
  }

  selectStatus(data) {
    this.currentFilter = data.value;
    this.runFilter();
  }

  onContentReady(event) {
    // set filter
    this.runFilter();
  }

  customiseToolbar(e, modMode = false) {
    if (this.calcSellingPricesMode) {
      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'normal',
            stylingMode: 'text',
            text: 'Round from cost up to nearest'
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxNumberBox',
          options: {
            required: true,
            showSpinButtons: false,
            showClearButton: true,
            width: 90,
            value: this.roundSellingPricesTo,
            onValueChanged: (e) => {
              this.roundSellingPricesTo = e.value;
            },
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'normal',
            stylingMode: 'text',
            text: 'then subtract'
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxNumberBox',
          options: {
            required: true,
            showSpinButtons: false,
            showClearButton: true,
            width: 90,
            value: this.lessAmount,
            min: 1,
            disabled: (this.upSellingPricesByPercent || this.upSellingPricesBy),
            onValueChanged: (e) => {
              this.lessAmount = e.value;
            }
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'success',
            stylingMode: 'outlined',
            text: 'Go',
            onClick: this.calcSellingPricesByRoundUp.bind(this)
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'default',
            stylingMode: 'outlined',
            text: 'Exit',
            onClick: this.cancelActualSellingPrices.bind(this)
          }
        });
    } else if (this.increaseSellingPricesMode) {
      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'normal',
            stylingMode: 'text',
            text: 'Amount to increase current selling price by'
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxNumberBox',
          options: {
            required: true,
            showSpinButtons: false,
            showClearButton: true,
            width: 90,
            value: this.upSellingPricesBy,
            min: 1,
            onValueChanged: (e) => {
              this.upSellingPricesBy = e.value;
            }
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'normal',
            stylingMode: 'text',
            text: '% to increase current selling price by'
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxNumberBox',
          options: {
            required: true,
            showSpinButtons: false,
            showClearButton: true,
            width: 90,
            value: this.upSellingPricesByPercent,
            onValueChanged: (e) => {
              this.upSellingPricesByPercent = e.value;
            }
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'success',
            stylingMode: 'outlined',
            text: 'Go',
            onClick: this.calcSellingPricesByRoundUp.bind(this)
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'default',
            stylingMode: 'outlined',
            text: 'Exit',
            onClick: this.cancelActualSellingPrices.bind(this)
          }
        });
    } else {
      this.gridService.addRefreshBtnToToolbar(e);
      this.gridService.addItemTotoolbar(e, 'modeTemplate', false);
      if (!modMode) {
        this.gridService.addItemTotoolbarPosition(e, 'activeFilterTemplate', true, 5, 3);

        if (this.houseTypeUseCommissionFields) {
          e.toolbarOptions.items.unshift(
            {
              location: 'after',
              locateInMenu: 'auto',
              widget: 'dxButton',
              options: {
                type: 'default',
                stylingMode: 'outlined',
                text: 'Commission Item',
                onClick: this.setCommissionItem.bind(this)
              }
            });
        }

        e.toolbarOptions.items.unshift(
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
              type: 'outline',
              text: this.editMode === 'row' ? 'Batch Edit Mode' : 'Row Edit Mode',
              onClick: this.setEditMode.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
              type: 'default',
              stylingMode: 'outlined',
              text: 'House Options',
              onClick: this.openHouseOptions.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
              type: 'default',
              stylingMode: 'outlined',
              text: 'Update Cost Price',
              onClick: this.updateSellingPricesCheck.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
              type: 'default',
              stylingMode: 'outlined',
              text: 'Reset Layout',
              onClick: this.clearStatePersistance.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
              type: 'default',
              stylingMode: 'outlined',
              text: 'Calculate Sales Prices',
              onClick: this.calculateActualSellingPrices.bind(this)
            }
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
              type: 'default',
              stylingMode: 'outlined',
              text: 'Increase Sales Prices',
              onClick: this.increaseActualSellingPrices.bind(this)
            }
          });
      }
    }
  }

  runFilter() {
    if (this.currentFilter === 'All') {
      this.grid.instance.clearFilter();
    } else if (this.currentFilter === 'Active Only') {
      this.grid.instance.filter(['isActive', '=', true]);
    } else {
      this.grid.instance.filter(['isActive', '=', false]);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });

    this.houseTypeService.clearCaches();
  }

  onSelectionChanged(cellInfo, e, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0]);
      e.component.close();
    }
  }

  setRecipeCellValue(rowData, value) {
    if (value) {
      rowData.recipeId = value.id;
    } else {
      rowData.recipeId = null;
    }
  }

  setRecipeCost(rowData, value, originalData) {
    if (value) {
      rowData.recipeCost = value;
    } else {
      rowData.recipeCost = null;
    }
    this.calcCostToCompanyFromCommission(rowData, originalData);
  }

  setCostPlusAmount(rowData, value, originalData) {
    if (value) {
      rowData.costPlusAmount = value;
    } else {
      rowData.costPlusAmount = null;
    }
    this.calcCostToCompanyFromCommission(rowData, originalData);
  }

  setPercentageMarkUp(rowData, value, originalData) {
    if (value) {
      rowData.markupPercent = value;
    } else {
      rowData.markupPercent = null;
    }
    if (this.houseTypeUseCommissionFields) {
      this.calcCostToCompanyFromCommission(rowData, originalData);
    }
  }

  setCostPlusPercentage(rowData, value, originalData) {
    if (value) {
      rowData.costPlusPercentage = value;
    } else {
      rowData.costPlusPercentage = null;
    }
    this.calcCostToCompanyFromCommission(rowData, originalData);
  }

  calcCostToCompanyFromCommission(rowData, originalData) {
    const recipeCost = rowData.recipeCost !== undefined ? rowData.recipeCost ?? 0 : originalData.recipeCost ?? 0;
    if (recipeCost) {
      const costPlusAmount = rowData.costPlusAmount !== undefined ? rowData.costPlusAmount ?? 0 : originalData.costPlusAmount ?? 0;
      const markupPercent = rowData.markupPercent !== undefined ? rowData.markupPercent ?? 0 : originalData.markupPercent ?? 0;
      const costPlusPercentage = rowData.costPlusPercentage !== undefined ? rowData.costPlusPercentage ?? 0 : originalData.costPlusPercentage ?? 0;
      rowData.costToCompany = this.calcNewCostToCompany2(recipeCost + costPlusAmount, markupPercent, costPlusPercentage);
    } else {
      rowData.costToCompany = null;
    }
  }

  calcNewCostToCompany2(costToCompany: number, markupPercent: number, costPlusPercentage: number): number {
    if (costToCompany && markupPercent && costPlusPercentage) {
      const sellingPriceExGst = Math.round((costToCompany * (1 + (markupPercent / 100))) / (1 - ((costPlusPercentage / 100) * (1 + (markupPercent / 100)))));
      return Math.round(sellingPriceExGst / (1 + (markupPercent / 100)));
    }
    return costToCompany;
  }

  onRecipeChanged(cellInfo, e) {
    // console.log(e);
    if (!e.value) {
      cellInfo.setValue({ id: null });
    }
  }

  updateSellingPricesCheck() {
    this.updatePricesPopup = true;
  }

  updateSellingPrices() {
    // update the selling prices from recipes
    this.loading = true;
    this.updatePricesPopup = false;
    this.subscriptions.push(
      this.houseTypeService.updateSellingPrices(this.reCostingDate)
        .subscribe(
          () => {
            this.houseTypeService.clearCaches();
            this.dataSource = this.houseTypeService.getHouseTypeDataSource();
            this.loading = false;
          },
          err => {
            this.notiService.notify(err);
            this.loading = false;
          })
    );
  }

  clearStatePersistance() {
    this.loading = true;
    localStorage.removeItem('house-types');
    this.grid.instance.clearSelection();
    setTimeout(() => {
      this.loading = false;
    }, 500); // wait
  }

  calculateMarkup(data) {
    if (!data.salesPrice || !data.costToCompany) {
      return null;
    }
    return ((data.salesPrice * 10 / 11) - data.costToCompany) / data.costToCompany * 100;
  }

  calculateSalesPrice(data) {
    if (!data.costToCompany || !data.markupPercent) {
      return null;
    }
    return data.costToCompany * (1 + data.markupPercent / 100) * 1.1;
  }

  calculateMarkupAmount(data) {
    if (!data.salesPrice || !data.costToCompany) {
      return null;
    }
    return (data.salesPrice * 10 / 11) - data.costToCompany;
  }

  calculateMargin(data) {
    if (!data.salesPrice || !data.costToCompany) {
      return null;
    }
    return ((data.salesPrice * 10 / 11) - data.costToCompany) / data.salesPrice * 100;
  }

  calculateHouseArea(data) {
    const houseMaster = this.houseTypeApiService.houseMasters.find(i => i.id === data.houseMasterId);
    return houseMaster?.houseArea;
  }

  calculateBlockFrontage(data) {
    const houseMaster = this.houseTypeApiService.houseMasters.find(i => i.id === data.houseMasterId);
    return houseMaster?.blockFrontage;
  }

  onCellPrepared(e) {
    if (e.rowType === 'data' && e.column.dataField === 'salesPrice') {
      // e.cellElement.style.backgroundColor = '#f5f5f5';
      e.cellElement.style.color = 'green';
      e.cellElement.style.fontWeight = 'bold';
      if (e.data.costToCompany && e.data.markupPercent) {
        const targetPrice = e.data.costToCompany * (1 + e.data.markupPercent / 100) * 1.1;
        const difference = Math.abs(e.data.salesPrice - targetPrice);
        if (difference > 1 && e.data.salesPrice < targetPrice) { //this will be nly coloured red if difference > $1 and salesPrice is below target
          e.cellElement.style.color = 'red';
        }
      }
    }
  }

  setHouseMasterCellValue(rowData, value, originalData) {
    if (value) {
      rowData.houseMasterId = value;
      this.setRowDescription(value, originalData.houseSpecificationId, rowData);
    } else {
      rowData.houseMasterId = null;
    }
  }

  setHouseSpecificationCellValue(rowData, value, originalData) {
    if (value) {
      rowData.houseSpecificationId = value;
      this.setRowDescription(originalData.houseMasterId, value, rowData);
    } else {
      rowData.houseSpecificationId = null;
    }
  }

  setRowDescription(houseMasterId: number, houseSpecificationId: number, rowData: any) {
    rowData.description = '';

    const houseMaster = this.houseTypeApiService.houseMasters.find(i => i.id === houseMasterId);
    if (houseMaster) {
      rowData.description = houseMaster.description;
    }

    const houseSpecification = this.houseTypeApiService.houseSpecifications.find(i => i.id === houseSpecificationId);
    if (houseSpecification) {
      rowData.description += ' - ' + houseSpecification.description;
    }
  }

  onInitNewRow(e) {
    e.data.isActive = true;
  }

  openHouseOptions() {
    const modalRef = this.modalService.open(HouseOptionsGridComponent, { windowClass: 'modal-houseOptions' });
    modalRef.result.then(() => { });
  }

  setCommissionItem() {
    const modalRef = this.modalService.open(SetCommissionItemComponent);
    modalRef.result.then(() => { });
  }

  setEditMode() {
    if (this.editMode === 'batch' && this.grid.instance.hasEditData()) {
      this.notiService.showInfo('Please Save or Cancel the edited data');
    } else {
      this.editMode = this.editMode === 'batch' ? 'row' : 'batch';
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 300); // wait
    }
  }

  calculateActualSellingPrices() {
    this.calcSellingPricesMode = true;
    this.enterCalculateSellingPricesMode();
  }

  increaseActualSellingPrices() {
    this.increaseSellingPricesMode = true;
    this.enterCalculateSellingPricesMode();
  }

  enterCalculateSellingPricesMode() {
    this.houseTypeIds = [];
    this.grid.instance.clearSelection();
    this.waitingClear = true;
    setTimeout(() => {
      this.waitingClear = false;
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 250);
    }, 500);
  }

  cancelActualSellingPrices() {
    this.calcSellingPricesMode = false;
    this.increaseSellingPricesMode = false;
    this.houseTypeIds = [];
    this.grid.instance.clearSelection();
    this.waitingClear = true;
    setTimeout(() => {
      this.waitingClear = false;
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 250);
    }, 500);
  }

  calcSellingPricesByRoundUp() {
    if (this.houseTypeIds.length === 0) {
      this.notiService.showInfo('Please select some house types');
      return;
    }

    // if (this.roundSellingPricesTo && (this.upSellingPricesBy || this.upSellingPricesByPercent)) {
    //   this.notiService.showInfo('Please select either round up or increase by, not both');
    //   return;
    // }

    if (this.calcSellingPricesMode && (this.roundSellingPricesTo || this.lessAmount)) {
      this.loading = true;
      this.subscriptions.push(
        this.houseTypeService.calculateActualSellingPrices(this.roundSellingPricesTo, this.houseTypeIds, this.lessAmount)
          .subscribe(
            () => {
              this.houseTypeService.clearCaches();
              this.dataSource = this.houseTypeService.getHouseTypeDataSource();
              this.loading = false;
              setTimeout(() => {
                this.cancelActualSellingPrices();
              }, 250);
            },
            err => {
              this.notiService.notify(err);
              this.calcSellingPricesMode = true;
              this.loading = false;
            })
      );
    } else if (this.upSellingPricesBy || this.upSellingPricesByPercent) {
      this.loading = true;
      this.subscriptions.push(
        this.houseTypeService.increaseActualSellingPrices(this.upSellingPricesBy, this.upSellingPricesByPercent, this.houseTypeIds)
          .subscribe(
            () => {
              this.houseTypeService.clearCaches();
              this.dataSource = this.houseTypeService.getHouseTypeDataSource();
              this.loading = false;
              setTimeout(() => {
                this.cancelActualSellingPrices();
              }, 250);
            },
            err => {
              this.notiService.notify(err);
              this.calcSellingPricesMode = true;
              this.loading = false;
            })
      );
    }
  }
}
