import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { IOptionList, OptionTypeEnum, SalesPriceEnum } from '.././types/option-list';
import { OptionModalComponent } from '.././option-modal/option-modal.component';
import { OptionColourEnum } from '.././types/option-list';
import { OptionListService } from '.././services/option-list.service';
import { GlobalService } from '../../services/global.service';
import { OptionListAttachmentService } from '../services/option-list-attachment.service';
import { HouseTypeApiService } from '../../maintenance/house-types/services/house-type.api.service';
import { NotificationService } from '../../services/notification.service';
import { OptionModalEventType, OptionModalReturnType, OptionModalMode } from '../types/option-list-misc.type';
import { GridService } from '../../services/grid.service';
import DataSource from 'devextreme/data/data_source';
import { HouseType } from '../../dtos/house-type';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { Recipe } from '../../dtos/recipe';
import { Column } from 'devextreme/ui/tree_list';


@Component({
  selector: 'js-option-list-dx',
  templateUrl: './option-list-dx.component.html',
  styleUrls: ['./option-list-dx.component.scss']
})
export class OptionListDxComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ShowImages: boolean;

  COMPONENT_NAME = 'option-list-dx';
  subscriptions: Subscription[] = [];
  isHouseOptions = false;
  includeInactive = false;
  loading = true;
  optionLists: IOptionList[];
  gridHeight: number;
  dataSource: DataSource<any, any>;
  houseTypes: HouseType[];
  useCache = false;
  moveCopyPopupVisible: boolean;
  hasChildren: boolean;
  copyChildren: boolean;
  copyOption: boolean;
  toNode: IOptionList;
  optionToMove: IOptionList;
  dropInsideItem: boolean;
  optionColourEnum = OptionColourEnum;
  salesPriceTypes: { id: number, description: string }[];
  recipes: Recipe[] = [];
  filterValue = '';
  showPrices = false;

  constructor(
    private optionListService: OptionListService,
    private globalService: GlobalService,
    public gridService: GridService,
    private optionListAttachmentService: OptionListAttachmentService,
    private modalService: NgbModal,
    private notiService: NotificationService,
    private houseTypeService: HouseTypeApiService,
    private estimatingService: EstimatingService
  ) {
    this.addSubOption = this.addSubOption.bind(this);
    this.editOption = this.editOption.bind(this);
    this.openOption = this.openOption.bind(this);
    this.calculateFilterExpression = this.calculateFilterExpression.bind(this);
  }

  ngOnInit() {
    this.setWindowHeight();
    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe(() => {
        this.setWindowHeight();
      })
    );
    this.salesPriceTypes = Object.keys(SalesPriceEnum).filter(k => isNaN(Number(k))).map(k => ({ id: SalesPriceEnum[k], description: k === 'Included In Base Price' ? 'Included' : k }));
    this.salesPriceTypes.unshift({ id: null, description: 'Priced' });
    this.getOptionListData();
  }

  ngOnChanges() {
  }

  ngOnDestroy() {
    this.houseTypeService.clearCaches();
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setWindowHeight() {
    this.gridHeight = window.innerHeight - 105;
  }

  getOptionListData() {
    this.subscriptions.push(
      this.optionListService.getOptionListData(this.useCache).subscribe({
        next: () => {
          this.recipes = this.estimatingService.recipes ?? [];
          this.houseTypes = this.houseTypeService.houseTypeCache ?? [];
          this.loading = false;
          this.setUpDataSource();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.optionListService.getOptionListsWithHouseTypes(false, !this.includeInactive).subscribe({
              next: (res) => {
                this.optionLists = res ?? [];
                return resolve(res.filter(i => this.isHouseOptions ? i.optionTypeId === OptionTypeEnum['House Option'] : i.optionTypeId !== OptionTypeEnum['House Option']));
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.optionListService.addOption(values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.optionListService.updateOptionDx(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      }
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          text: 'Standard Options',
          type: this.isHouseOptions ? 'outlined' : 'default',
          onClick: this.goToStandardOptions.bind(this)
        }
      },
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          text: 'House Options',
          type: this.isHouseOptions ? 'default' : 'outlined',
          onClick: this.goToHouseOptions.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxCheckBox',
        options: {
          text: 'Show Prices',
          value: this.showPrices,
          rtlEnabled: true,
          onValueChanged: (e) => {
            this.showPrices = e.value;
          }
        },
      },
      {
        location: 'after',
        widget: 'dxCheckBox',
        options: {
          text: 'Include Inactive',
          value: this.includeInactive,
          rtlEnabled: true,
          onValueChanged: (e) => {
            this.includeInactive = e.value;
            this.setUpDataSource();
          }
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          hint: 'Add Option',
          onClick: this.addOption.bind(this)
        }
      }
    );
  }

  goToStandardOptions() {
    if (this.isHouseOptions) {
      this.changeOptionListType();
    }
  }

  goToHouseOptions() {
    if (!this.isHouseOptions) {
      this.changeOptionListType();
    }
  }

  changeOptionListType() {
    this.isHouseOptions = !this.isHouseOptions;
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.setUpDataSource();
    }, 200);
  }

  addOption() {
    this.openOption(OptionModalMode.add, null);
  }

  addSubOption(e) {
    this.openOption(OptionModalMode.add, e.row.data);
  }

  editOption(e) { // need to declare fu
    this.openOption(OptionModalMode.edit, e.row.data);
  }

  openOption(editMode: number, option: IOptionList) {
    const modalRef = this.modalService.open(OptionModalComponent, { backdrop: 'static', windowClass: 'modal-1000' });
    modalRef.componentInstance.modalHeading = 'Edit Option';
    modalRef.componentInstance.mode = editMode;
    modalRef.componentInstance.parent = option?.optionListIdAbove ? { data: this.optionLists.find(i => i.id === option.optionListIdAbove) } : null;
    modalRef.componentInstance.option = option;
    modalRef.componentInstance.isRoot = !option || option.optionListIdAbove === null;
    modalRef.componentInstance.isHouseOption = this.isHouseOptions;

    // update deleted img before modal closes as img deletion is applied immediately
    modalRef.componentInstance.modalEvent.subscribe((res: OptionModalEventType) => {
      if (res && res.deleteImage) {
        option.attachmentId = null;
      }
      if (res && res.forceRefresh) {
        this.setUpDataSource();
      }
    }, () => { });

    modalRef.result.then((result: OptionModalReturnType) => {
      if (result) {
        this.setUpDataSource();
      }
    }, () => { });
  }

  // onDragChange(e: DxTreeListTypes.RowDraggingChangeEvent) {
  //   const visibleRows = e.component.getVisibleRows();
  //   const sourceNode = e.component.getNodeByKey(e.itemData.ID);
  //   let targetNode = visibleRows[e.toIndex].node;

  //   while (targetNode && targetNode.data) {
  //     if (targetNode.data.ID === sourceNode.data.ID) {
  //       e.cancel = true;
  //       break;
  //     }
  //     targetNode = targetNode.parent;
  //   }
  // }

  onReorder = (e) => {
    const visibleRows = e.component.getVisibleRows();

    this.toNode = visibleRows[e.toIndex].data;
    this.optionToMove = e.itemData;
    this.dropInsideItem = false
    this.copyOption = false;
    this.copyChildren = true;

    if (this.toNode.optionListIdAbove !== this.optionToMove.optionListIdAbove || e.dropInsideItem) {
      this.hasChildren = this.optionLists.some(i => i.optionListIdAbove === this.optionToMove.id)
      this.moveCopyPopupVisible = true;
      if (e.dropInsideItem) {
        this.dropInsideItem = true;
      }
    } else {
      this.runCopyMove();
    }
  }

  runCopyMove() {
    this.moveCopyPopupVisible = false;

    if (!this.copyOption) {
      let parent = this.toNode.optionListIdAbove;
      let index = this.toNode.orderNo;

      if (this.dropInsideItem) {
        parent = this.toNode.id;
        index = 1;
      }
      this.subscriptions.push(
        this.optionListService.moveOption(this.optionToMove.id, parent, index).subscribe({
          next: () => {
            this.setUpDataSource();
          }, error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    } else {
      if (this.dropInsideItem) {
        this.optionToMove.optionListIdAbove = this.toNode.id;
        this.optionToMove.orderNo = 1;
      } else {
        this.optionToMove.optionListIdAbove = this.toNode.optionListIdAbove;
        this.optionToMove.orderNo = this.toNode.orderNo;
      }

      this.optionListService.addOption(this.optionToMove).subscribe(
        optionRes => {
          if (this.copyChildren) {
            this.copyChildrenOptions(this.optionToMove.id, optionRes.id);
          } else {
            this.setUpDataSource();
          }
        },
        err => {
          this.notiService.notify(err);
        }
      );
    }
  }

  copyChildrenOptions(previousId: number, newId: number) {
    this.subscriptions.push(
      this.optionListService.copyOptionChildren(previousId, newId).subscribe({
        next: () => {
          this.setUpDataSource();
        }, error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  copyAttachment(option, attachment) {
    this.subscriptions.push(
      this.optionListAttachmentService.postOptionListAttachment(option.id, attachment).subscribe({
        next: () => {
          this.setUpDataSource();
        }, error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  cellTemplate(container, options) {
    const noBreakSpace = '\u00A0',
      text = (options.value || []).map(element => {
        return options.column.lookup.calculateCellValue(element);
      }).join(', ');
    container.textContent = text || noBreakSpace;
    // container.title = text;
    container.style.maxHeight = 55 + 'px';
    container.style.display = 'block';
  }

  calculateFilterExpression(filterValue, selectedFilterOperation, target) {
    return ['houseTypeIds', 'contains', filterValue];
  }

  onRowDblClick(e) {
    if (e.isExpanded) {
      e.component.collapseRow(e.key);
    } else {
      e.component.expandRow(e.key);
    }
  }
}
