import { Category } from '../../dtos/category';

export enum OptionAddType {
    sibling = 1,
    child = 2,
    copy = 3
}

export enum SalesPriceEnum {
    'Priced' = 0,
    'Note' = 1,
    'No Charge' = 2,
    'Provisional Sum' = 4,
    'Included In Base Price' = 7,
    'Promotion' = 8,
    'Bonus Item' = 9
}

/*
export enum SalesPriceNoProvisionalEnum {
    'Standard' = 0,
    'Note' = 1,
    'No Charge' = 2,
    'Included In Base Price' = 7,
    'Promotion' = 8,
    'BonusItem' = 9
}
*/

export enum OptionTypeEnum {
   'Standard' = 0,
   'Selected At PreStart' = 1,
   // 'Not Applicable' = 2,
   // 'Note' = 3,
   // 'Dropdown' = 4,
   'House Option' = 5, // is server option type but not in UI dropdown - changed GH 10-7-21 - errors in filtering lists?
   'Not Valid Till Selected' = 6,
   'Header' = 7 // not in server enum but used in UI dropdown. Headers are saved as standard type + converted in UI!
}

export enum OptionColourEnum {
    'Standard' = 0,
    'Red' = 1,
    'Green' = 2
}

export class IOptionList {
    id: number;
    // TODO: server to add 'header' option type and remove 'notSelectable' from IOption
    optionTypeId: OptionTypeEnum;
    notSelectable: boolean; // if true is a HEADER OPTION
    description: string;
    printedDescription: string;
    defaultDescription: string;
    warningNote: string;
    childSelectionWarning: string;
    productCode: string;
    optionColour: OptionColourEnum;
    orderNo: number;
    isActive: boolean;
    isBoldText: boolean;
    optionListIdAbove: number;
    childItemSelectableByClient: boolean;
    houseTypeId: number;

    // pricing
    isQtyRequired: boolean;
    salesPriceIfAdded: number;
    salesPriceTypeIfAddedId: SalesPriceEnum;
    salesPriceIfChangedInSameList: number;
    salesPriceTypeIfChangedInSameListId: SalesPriceEnum;
    costToCompanyIfAdded: number; // not used
    costToCompanyIfChangedInSameList: number; // not used

    children: IOptionList[];

    /* used for filter in UI  */
    // path_string: string;

    // images
    attachmentId: number; // uploaded file present if not null. to be replaced with hasBlob
    attachmentName: string;
    attachmentTypeId: number;
    optionImageId: number; // image library
    imageNotPrinted: boolean; // image can be just for selection or on-line selection but is not printed - e.g. can have a linked item.
    // hasBlob: boolean; // has uploaded file attachment

    optionListCategories: Category[];

    // estimating
    priceIfAddedRecipeId: number;
    priceIfChangedRecipeId: number;

    // if isQuantityRequired then can hide from the main screen and reports
    hideQuantity: boolean;

    // system
    lastPriceUpdate: Date;
    lastPriceUpdatedByUserId: number;

    // for export
    level1Name: string;
    level2Name: string;
    level3Name: string;
    level4Name: string;

    static copyOptionTreeNodeIntoOption(option: IOptionList, treeNode) {
        option.description = treeNode.description;
        option.isBoldText = treeNode.isBoldText;
        option.optionColour = treeNode.optionColour;
        option.warningNote = treeNode.warningNote;
        option.childSelectionWarning = treeNode.childSelectionWarning;
        option.productCode = treeNode.productCode;
        option.isActive = treeNode.isActive;
        option.notSelectable = treeNode.notSelectable;
        option.childItemSelectableByClient = treeNode.childItemSelectableByClient;
        option.defaultDescription = treeNode.defaultDescription;
        option.printedDescription = treeNode.printedDescription;
        option.houseTypeId = treeNode.houseTypeId;
        option.optionTypeId = treeNode.optionTypeId ? treeNode.optionTypeId : null;
        option.salesPriceIfAdded = +treeNode.salesPriceIfAdded;
        option.salesPriceTypeIfAddedId = treeNode.salesPriceTypeIfAddedId;
        option.salesPriceIfChangedInSameList = treeNode.salesPriceIfChangedInSameList;
        option.salesPriceTypeIfChangedInSameListId = treeNode.salesPriceTypeIfChangedInSameListId;
        // option.costToCompanyIfAdded = +treeNode.costToCompanyIfAdded;
        // option.costToCompanyIfChangedInSameList = +treeNode.costToCompanyIfChangedInSameList;
        option.priceIfAddedRecipeId = treeNode.priceIfAddedRecipeId;
        option.priceIfChangedRecipeId = treeNode.priceIfChangedRecipeId;
        option.isQtyRequired = treeNode.isQtyRequired;
        option.optionImageId = treeNode.optionImageId;
        option.imageNotPrinted = treeNode.imageNotPrinted;
        option.attachmentId = treeNode.attachmentId;
        option.hideQuantity = treeNode.hideQuantity;
    }
}

