import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: true,
    decimal: '.',
    precision: 2,
    prefix: '',
    suffix: '',
    thousands: ','
};

/* DX */
import {
    DxDataGridModule,
    DxToolbarModule,
    DxButtonModule,
    DxTabPanelModule,
    DxFileUploaderModule,
    DxDropDownBoxModule,
    DxHtmlEditorModule,
    DxPopupModule,
    DxDateBoxModule,
    DxSelectBoxModule,
    DxColorBoxModule,
    DxNumberBoxModule,
    DxRadioGroupModule,
    DxTreeListModule,
    DxCheckBoxModule,
    DxFormModule,
    DxLoadPanelModule,
    DxTagBoxModule
} from 'devextreme-angular';

/* MATERIAL */
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

/* APP */
import { ShowImageComponent } from './show-image/show-image.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { BtnSpinnerComponent } from './btn-spinner/btn-spinner.component';
import { BackgroundLogoComponent } from './background-logo.component';
import { DebounceClickDirective } from './debounce-click';
import { GenericFilterPipe } from './genericFilter.pipe';
import { HelpComponent, HelpService, HelpModalComponent, HelpActivateComponent } from './help.component';
import { TextHighlighterDirective } from './textHighlighter.directive';
import { ConfirmationModalComponent } from './confirmation-modal.component';
import { FocusElementDirective } from './focus.directive';
import { HtmlEditorComponent } from './html-editor/html-editor.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TreeModule } from '@circlon/angular-tree-component';


@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        CurrencyMaskModule,
        MatExpansionModule,
        MatInputModule,
        MatSelectModule,
        MatTooltipModule,
        MatTableModule,
        MatTabsModule,
        MatCheckboxModule,
        MatNativeDateModule,
        MatFormFieldModule,
        DxDataGridModule,
        DxToolbarModule,
        DxButtonModule,
        DxTabPanelModule,
        DxFileUploaderModule,
        DxDropDownBoxModule,
        DxPopupModule,
        DxDateBoxModule,
        DxSelectBoxModule,
        DxHtmlEditorModule,
        DxColorBoxModule,
        DxNumberBoxModule,
        DxRadioGroupModule,
        DxTreeListModule,
        DxCheckBoxModule,
        DxLoadPanelModule,
        ImageCropperModule,
        NgbCollapseModule,
        TreeModule,
        DxFormModule,
        DxTagBoxModule
    ],
    declarations: [
        ShowImageComponent,
        LoadingSpinnerComponent,
        BtnSpinnerComponent,
        BackgroundLogoComponent,
        DebounceClickDirective,
        HelpComponent,
        HelpModalComponent,
        HelpActivateComponent,
        GenericFilterPipe,
        TextHighlighterDirective,
        FocusElementDirective,
        ConfirmationModalComponent,
        HtmlEditorComponent
    ],
    providers: [
        HelpService,
        NgxImageCompressService,
        { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
    ],
    exports: [
        // ng
        NgbModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        // package
        CurrencyMaskModule,
        // mat
        MatExpansionModule,
        MatInputModule,
        MatSelectModule,
        MatTooltipModule,
        MatTableModule,
        MatTabsModule,
        MatCheckboxModule,
        MatNativeDateModule,
        MatFormFieldModule,
        // dx
        DxDataGridModule,
        DxToolbarModule,
        DxButtonModule,
        DxTabPanelModule,
        DxFileUploaderModule,
        DxDropDownBoxModule,
        DxPopupModule,
        DxDateBoxModule,
        DxSelectBoxModule,
        DxHtmlEditorModule,
        DxColorBoxModule,
        DxTabPanelModule,
        DxNumberBoxModule,
        DxRadioGroupModule,
        DxTreeListModule,
        DxCheckBoxModule,
        DxFormModule,
        DxLoadPanelModule,
        DxTagBoxModule,
        // project
        ShowImageComponent,
        LoadingSpinnerComponent,
        BtnSpinnerComponent,
        BackgroundLogoComponent,
        HelpComponent,
        HelpModalComponent,
        HelpActivateComponent,
        HtmlEditorComponent,
        GenericFilterPipe,
        TextHighlighterDirective,
        FocusElementDirective,
        ConfirmationModalComponent,
        DebounceClickDirective,
        ImageCropperModule,
        NgbCollapseModule,
        TreeModule
    ]
})
export class SharedModule { }
