import { SharedModule } from './../shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OptionListsComponent } from './option-lists.component';
import { OptionListStartComponent } from './option-list-start/option-list-start.component';
import { OptionListBoardStartComponent } from './boards/option-list-board-start.component';
import { OptionListBoardsFirstComponent } from './boards/option-list-boards-first.component';
import { OptionModalComponent } from './option-modal/option-modal.component';
import { CategoriesComponent } from './categories/categories.component';
import { CategoryModalComponent } from './categories/category-modal/category-modal.component';
import { OptionCategoryModalComponent } from './categories/option-category-modal/option-category-modal.component';

import { ImageLibraryService } from '../services/felixApi/image-library.service';
import { OptionListService } from './services/option-list.service';
import { BoardMasterService } from './boards/services/board-master.service';
import { BoardItemService } from './boards/services/board-item.service';
import { CategoryApiService } from './categories/services/categories-api.service';
import { BulkUpdateComponent } from './bulk-update/bulk-update.component';
import { BoardTypesComponent } from './boards/board-types/board-types.component';
import { OptionHeaderFormComponent } from './option-header-form/option-header-form.component';
import { HouseOptionsGridComponent } from './house-options-grid/house-options-grid.component';
import { CopyChildrenComponent } from './copy-children/copy-children.component';
import { RecipeSearchComponent } from './recipe-search/recipe-search.component';
import { OptionListDxComponent } from './option-lists/option-list-dx.component';


@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        OptionListsComponent,
        OptionListStartComponent,
        OptionListBoardStartComponent,
        OptionListBoardsFirstComponent,
        OptionModalComponent,
        OptionHeaderFormComponent,
        CategoriesComponent,
        CategoryModalComponent,
        OptionCategoryModalComponent,
        BulkUpdateComponent,
        BoardTypesComponent,
        HouseOptionsGridComponent,
        CopyChildrenComponent,
        RecipeSearchComponent,
        OptionListDxComponent
    ],
    providers: [
        ImageLibraryService,
        OptionListService,
        CategoryApiService,
        BoardMasterService,
        BoardItemService
    ],
    exports: []
})
export class OptionListsModule { }
