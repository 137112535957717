import { Component, OnInit, ElementRef, Input, ViewChild, AfterViewInit, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { TreeNode } from '@circlon/angular-tree-component';

import {
  IOptionList, SalesPriceEnum,
  OptionColourEnum, OptionTypeEnum
} from '../types/option-list';
import { IOptionListAttachment } from '../types/option-list-attachment';
import { OptionAddType } from '../types/option-list';
import { OptionImageModalComponent } from '../option-image-modal/option-image-modal.component';
import { OptionCategoryModalComponent } from '../categories/option-category-modal/option-category-modal.component';
import { HouseType } from '../../dtos/house-type';

import { OptionListService } from '../services/option-list.service';
import { OptionListAttachmentService } from '../services/option-list-attachment.service';
import { GlobalService } from '../../services/global.service';
import { NotificationService } from '../../services/notification.service';
import { HouseTypeApiService } from '../../maintenance/house-types/services/house-type.api.service';
import { ConfirmationModalComponent } from '../../shared/confirmation-modal.component';
import { FileProcessingService } from '../../services/file-processing.service';
import { OptionModalEventType, OptionModalReturnType, OptionModalMode } from '../types/option-list-misc.type';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { LogService } from '../..//services/log.service';
import { OptionHeaderService } from '../option-header-form/option-header.service';
import { AuthService } from '../../services/auth.service';
import { Recipe } from '../../dtos/recipe';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import { RecipeTypeEnum } from '../../dtos/recipe-type.enum';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import * as saveAs from 'file-saver';
import { RecipeSearchComponent } from '../recipe-search/recipe-search.component';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';
import { AttachmentTypeEnum } from '../../dtos/attachment-type.enum';
import ArrayStore from 'devextreme/data/array_store';


@Component({
  selector: 'js-option-modal',
  templateUrl: './option-modal.component.html',
  styleUrls: ['./option-modal.component.scss'],
  providers: [OptionHeaderService]
})
export class OptionModalComponent implements OnInit, OnDestroy, AfterViewInit {

  COMPONENT_NAME = 'option-lists';

  @Input() option: IOptionList; // option being EDITed / node that called modal in case of ADD modal. null if adding from BASE OPTION button
  @Input() parent: TreeNode; // Warning: will be garbage if node is root
  @Input() isRoot: boolean;
  @Input() isHouseOption: boolean;

  @Input() mode: OptionModalMode;
  submitText: string;
  optionMode = OptionModalMode;

  public modalEvent = new EventEmitter<OptionModalEventType>();

  @ViewChild('descInput', { static: true }) descInput: ElementRef;
  @ViewChild('priceLabel1', { static: false }) priceLabel1: ElementRef;
  @ViewChild('priceLabel2', { static: false }) priceLabel2: ElementRef;
  @ViewChild('ngxImgScroll', { static: false }) ngxImgScroll: ElementRef;

  @ViewChild('fileUploader') fileUploader: DxFileUploaderComponent;

  currParentId: number;
  salesPriceEnum = SalesPriceEnum;
  salesPriceKeys: any;
  optionColourEnum = OptionColourEnum;
  colourKeys: any;
  optionTypeEnum = OptionTypeEnum;
  isHeader = false;
  optionAddTypeEnum = OptionAddType;
  optionAddType: number;
  optionTypes: { id: number; description: string; }[] = [
    { id: OptionAddType.sibling, description: 'Sibling' },
    { id: OptionAddType.child, description: 'Child' }
  ];
  houseTypes: HouseType[];
  qtyMode = false;
  fileToUpload: string;
  fileAttached = false;
  optionListAttachment: IOptionListAttachment;
  recipes: Recipe[];
  imageFound = false;
  modalImageLoading = false;
  deleteImgSelected = false;
  imgSectionExpanded = false;
  costSectionExpanded = false;
  modalHeading: string;
  optionForm: UntypedFormGroup;
  updatedOption: IOptionList;
  addingAnother = false;
  haveLibraryImage = false;
  removingLibImg = false;
  newLibImgId = null;
  subscriptions: Subscription[] = [];
  innerWidth: number;
  smallWindowThreshold = 510;
  submitLoading = false;
  lighten = false;
  confirmingDelete = false;
  headerStylingEnabled = false;
  customerUpdateAllowed: boolean;
  refreshingRecipes: boolean;
  addedRecipeId: number[];
  searchExprOption: any = ['recipeCode', 'description'];
  changedRecipeId: number[];
  dropDownOptions: object;
  compressedImageFile: string;
  addedOptionOrderNo: number; // when click add another it will use the order no returned
  deleteChildren = false;
  loading = true;
  optionRecordTypes: { id: number; description: string; }[];
  fileType: string;
  wrongType: boolean;
  imageFileLoaded: boolean;
  imageFile: any;
  attachmentTypeEnum = AttachmentTypeEnum;
  allowedAttachmentType: string;
  houseTypesDataSource: ArrayStore;
  selectedHouseTypeIds: number[] = [];
  originalSelectedHouseTypeIds: number[] = [];
  optionListId: number;
  houseTypesPlaceHolder = 'House Types (Blank for All)...';

  constructor(
    private _optionListService: OptionListService,
    private _optionListAttachmentService: OptionListAttachmentService,
    private houseTypeService: HouseTypeApiService,
    private notiService: NotificationService,
    private _globalService: GlobalService,
    private logService: LogService,
    private estimatingService: EstimatingService,
    private imgService: FileProcessingService,
    private formBuilder: UntypedFormBuilder,
    private auth: AuthService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public headerService: OptionHeaderService
  ) {
    this.recipeChanged = this.recipeChanged.bind(this);
    this.dropDownOptions = { width: 800, height: 600 };

    this.subscriptions.push(
      this._globalService.innerWidthChanged.subscribe(width =>
        this.innerWidth = width
      ),
      this.estimatingService.getRecipeWithSellingRates(true).subscribe(recipes =>
        this.recipes = recipes.filter(i => i.recipeTypeId === RecipeTypeEnum.Recipe)
      ),
      this.headerService.headerFetched.subscribe(fetched =>
        this.findImage()
      )
    );
  }

  ngOnInit() {
    this.innerWidth = this._globalService.innerWidth;
    this.currParentId = this.isRoot ? null : this.parent ? this.parent.data.id : null;

    if (this.isHouseOption) {
      this.allowedAttachmentType = 'Image or PDF';
    } else {
      this.allowedAttachmentType = 'Image';
    }

    // check configs
    this._globalService.companyConfiguration.forEach(config => {
      if (config.configurationId === ConfigurationEnum.CustomerUpdateAllowed && config.configurationValue === 1) {
        this.customerUpdateAllowed = true;
      }
    });

    if (this.auth.isAdminOrSuper() && this.customerUpdateAllowed) {
      this.headerStylingEnabled = true;
    }

    if (this.mode === OptionModalMode.edit) {
      this.submitText = 'Update';
      this.optionListId = this.option.id;
      this.editSetup();
    } else {
      this.submitText = 'Add';
      this.addSetup();
    }

    this.getHouseTypes();

    this.salesPriceKeys = Object.keys(this.salesPriceEnum).filter(k => isNaN(Number(k))).filter(k => k !== 'null');
    this.colourKeys = Object.keys(this.optionColourEnum).filter(k => isNaN(Number(k)));
    this.optionRecordTypes = [
      { id: 0, description: 'Standard Option' },
      { id: 1, description: '\'To be Selected\' Option' },
      { id: 6, description: 'Requires child option to be selected for quotes' },
      { id: 7, description: 'Header/Spacer' }
    ]
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  ngAfterViewInit() {
    if (!this.isHeader && this.mode === OptionModalMode.edit) {
      this.priceAddedChange(String(this.optionForm.controls['salesPriceAddedId'].value), false);
      this.priceChangedChange(String(this.optionForm.controls['salesPriceChangedId'].value), false);
    }

    this.descInput?.nativeElement.focus();
  }

  getHouseTypes() {
    this.houseTypeService.getHouseTypesCached().subscribe(types => {
      this.houseTypes = types;
      this.houseTypesDataSource = new ArrayStore({
        data: types,
        key: 'id',
      });
      this.getOptionListHouseTypes();
    }, err => {
      this.notiService.notify(err);
      this.loading = false;
    });
  }

  getOptionListHouseTypes() {
    this.selectedHouseTypeIds = [];
    this.originalSelectedHouseTypeIds = [];
    if (this.option && this.mode === OptionModalMode.edit) {
      this._optionListService.getOptionListHouseTypes(this.option.id).subscribe(optionListHouseTypes => {
        this.selectedHouseTypeIds = optionListHouseTypes.map(h => h.houseTypeId);
        this.originalSelectedHouseTypeIds = optionListHouseTypes.map(h => h.houseTypeId);
        this.loading = false;
        this.processHouseOptions();
      }, err => {
        this.notiService.notify(err);
        this.loading = false;
      });
    } else {
      this.loading = false;
      this.processHouseOptions();
    }
  }

  processHouseOptions() {
    if (this.isHouseOption) {
      this.houseTypesPlaceHolder = 'Inherited from Parent';
      this.optionForm.controls['optionType'].disable();
      this.optionForm.controls['optionType'].setValue(5);

      if (this.isRoot || !this.option) {
        this.houseTypes.push(this.getNullHouseType('root'));        
      this.houseTypesPlaceHolder = 'Select House Type or leave blank for Generic';
      } else {
        this.houseTypes.push(this.getNullHouseType('child'));
      }

      /* If ADDing then default to relevant null house type otherwise use existing house type if there is one */
      const houseTypeID = this.option && this.option.houseTypeId && this.mode === OptionModalMode.edit ? this.option.houseTypeId : -1;
      // const houseTypeObj = this.getHouseTypeObject(houseTypeID);
      this.optionForm.controls['houseTypeId'].setValue(houseTypeID);
    }
  }

  editSetup() {
    this.optionForm = this.formBuilder.group({
      description: [this.option.description, Validators.required],
      itemPrefix: this.option.defaultDescription,
      printedDesc: this.option.printedDescription,
      warningNote: this.option.warningNote,
      childSelectionWarning: this.option.childSelectionWarning,
      productCode: this.option.productCode,
      optionColour: this.option.optionColour,
      isBoldText: this.option.isBoldText,
      isActive: this.option.isActive,
      optionType: this.option.notSelectable ? OptionTypeEnum.Header : this.option.optionTypeId ? this.option.optionTypeId : 0,
      houseTypeId: this.option.houseTypeId, // set after based on conditions,
      qtyRequired: this.option.isQtyRequired,
      hideQuantity: this.option.hideQuantity,
      clientChildren: this.option.childItemSelectableByClient,
      salesPriceAdded: this.option.salesPriceIfAdded,
      salesPriceAddedId: this.option.salesPriceTypeIfAddedId ? this.option.salesPriceTypeIfAddedId : 0,
      salesPriceChanged: this.option.salesPriceIfChangedInSameList,
      salesPriceChangedId: this.option.salesPriceTypeIfChangedInSameListId ? this.option.salesPriceTypeIfChangedInSameListId : 0,
      costChanged: this.option.costToCompanyIfChangedInSameList,
      costAdded: this.option.costToCompanyIfAdded,
      addedRecipe: this.option.priceIfAddedRecipeId,
      changedRecipe: this.option.priceIfChangedRecipeId,
      mode: '',
      imageNotPrinted: this.option.imageNotPrinted,
      notSelectable: this.option.notSelectable ?? false
    });

    if (this.option.notSelectable && !this.isHouseOption) {
      this.isHeader = true;
      this.headerService.fetchHeader(this.option.id.toString());
    }
    if (this.option.attachmentId) {
      this.findImage();
    }
    if (this.option.optionImageId) {
      this.haveLibraryImage = true;
    }
  }

  addSetup() {
    this.setHeading();
    this.optionForm = this.formBuilder.group({
      description: [null, Validators.required],
      printedDesc: null,
      itemPrefix: null,
      warningNote: null,
      childSelectionWarning: null,
      productCode: null,
      optionColour: 0,
      isBoldText: null,
      optionType: 0,
      houseTypeId: null,
      qtyRequired: false,
      hideQuantity: false,
      isActive: true,
      clientChildren: false,
      salesPriceAdded: 0,
      salesPriceAddedId: 0,
      salesPriceChanged: 0,
      salesPriceChangedId: 0,
      costChanged: null,
      costAdded: null,
      addedRecipe: null,
      changedRecipe: null,
      imageNotPrinted: false,
      notSelectable: false
    });
  }

  launchImageLibrary() {
    this.lighten = true;
    const modalRef = this.modalService.open(OptionImageModalComponent, { windowClass: 'modal-center' });

    modalRef.result.then((inLibImgId) => {
      this.lighten = false;
      if (inLibImgId) {
        this.haveLibraryImage = true;
        this.removingLibImg = false;
        this.newLibImgId = inLibImgId;
      }
    }, (reason) => {
      this.lighten = false;
    });
  }

  removeLibraryImage() {
    this.removingLibImg = !this.removingLibImg;
  }

  optionTypeChange(e) {
    if (+e.value === this.optionTypeEnum.Header) {
      this.isHeader = true;
      // see if there is existing header record if switching to header type
      if (this.mode === OptionModalMode.edit && !this.headerService.currentHeader) {
        this.headerService.fetchHeader(this.option.id.toString());
      }
    } else {
      this.isHeader = false;
      setTimeout(() => {
        this.priceAddedChange(String(this.optionForm.controls['salesPriceAddedId'].value), false);
        this.priceChangedChange(String(this.optionForm.controls['salesPriceChangedId'].value), false);
      }, 100);
    }
  }

  priceAddedChange(e, clearOnEnable = true) {
    if (e === '0' || e === '4' || e === '7' || e.value === '0' || e.value === '4' || e.value === '7') {
      this.optionForm.get('salesPriceAdded').enable();
      if (clearOnEnable) {
        this.priceLabel1.nativeElement.textContent = '';
      }
    } else {
      this.optionForm.get('salesPriceAdded').setValue(null);
      this.priceLabel1.nativeElement.textContent = 'Not Applicable';
      this.optionForm.get('salesPriceAdded').disable();
    }
  }

  priceChangedChange(e, clearOnEnable = true) {
    if (e === '0' || e === '4' || e === '7' || e.value === '0' || e.value === '4' || e.value === '7') {
      this.optionForm.get('salesPriceChanged').enable();
      if (clearOnEnable) {
        this.priceLabel2.nativeElement.textContent = '';
      }
    } else {
      this.optionForm.get('salesPriceChanged').setValue(null);
      this.priceLabel2.nativeElement.textContent = 'Not Applicable';
      this.optionForm.get('salesPriceChanged').disable();
    }
  }

  setHeading() {
    this.optionAddType = OptionAddType.child;
    if (!this.option) {
      this.modalHeading = 'Add new base option';
    } else {
      let shortenString = '';
      if (this.option.description.length > 50) {
        shortenString = this.option.description.slice(0, 50) + '...';
      } else {
        shortenString = this.option.description;
      }
      this.modalHeading = 'Add option below \'' + shortenString + '\'';
    }
  }

  submit() {
    if (this.optionForm.valid) {
      this.submitLoading = true;
      this.imgSectionExpanded = false;
      this.costSectionExpanded = false;
      this.optionForm.disable();

      if (this.mode === OptionModalMode.add) {
        this.addingAnother = false;
        this.addOption();
      } else {
        this.editOption();
      }
    } else {
      this.notiService.showWarning('Please complete all required fields');
    }
  }

  addAnotherSelected() {
    this.addingAnother = true;
    this.addOption();
  }

  addOption() {
    this.submitLoading = true;
    this.setUpdatedAddOption();

    this._optionListService.addOption(this.updatedOption).subscribe(
      res => {
        this.optionListId = res.id;
        // if we added as child set new parent
        if (this.optionAddType === OptionAddType.child) {
          this.currParentId = this.option?.id;
        }

        this.updatedOption = res as IOptionList;

        if (this.isHeader) {
          this.headerService.addOptionHeader(res.id.toString(), this.fileToUpload)
            .subscribe(() => {
              this.addOptionListHouseTypes();
            }, err => {
              this.genericError(err, 'addOptionHeader()');
            });
        } else {
          if (this.fileAttached) {
            this.saveImage();
          } else {
            this.updatedOption.attachmentId = null;
            this.addOptionListHouseTypes();
          }
        }
      },
      err => {
        if (err.message.indexOf('already exists')) {
          // incase another user has added an option with same name, refresh
          this.modalEvent.emit({
            forceRefresh: true,
            failed: true
          });
          this.genericError(err, 'addOption()', 'An Option with this name already exists. The list has been refreshed.');
        } else {
          this.genericError(err, 'addOption()', 'Error adding Option');
        }
      }
    );
  }

  setUpdatedAddOption() {
    this.setBaseUpdatedOption();

    // temporary until backend knows about header optionType
    if (this.isHeader) {
      this.updatedOption.optionTypeId = OptionTypeEnum.Standard;
    }

    /* Unless we are a dummy null house type, use the value  */
    const houseType = this.optionForm.get('houseTypeId').value;
    this.updatedOption.houseTypeId = houseType !== -1 ? houseType : null;

    if (this.isHouseOption) {
      this.updatedOption.notSelectable = this.optionForm.get('notSelectable').value;
    }
    if (this.newLibImgId) {
      this.updatedOption.optionImageId = this.newLibImgId;
      this.updatedOption.attachmentId = null;
    }
    if (this.removingLibImg) {
      this.updatedOption.optionImageId = null;
    }
    if (this.optionAddType === this.optionAddTypeEnum.sibling) {
      this.updatedOption.optionListIdAbove = this.currParentId;
      this.updatedOption.orderNo = this.addedOptionOrderNo ? this.addedOptionOrderNo
        : this.option ? this.option.orderNo + 1 : null;
    } else if (this.optionAddType === this.optionAddTypeEnum.child) {
      this.updatedOption.orderNo = null;
      this.updatedOption.optionListIdAbove = this.option?.id;
    } else {
      this.logService.log(this.COMPONENT_NAME, 'setUpdatedAddOption()', null, 'unrecognised optionAddType');
    }
  }

  editOption() {
    this.setUpdatedEditOption();

    this._optionListService.updateOption(this.updatedOption).subscribe(
      res => {
        if (this.isHeader) {
          this.headerService.editHeader(this.fileToUpload)
            .subscribe(rs => {
              this.addOptionListHouseTypes();
              // this.notiService.showSuccess('Option Updated');
              // this.close({ option: this.updatedOption });
            }, err => {
              this.genericError(err, 'editHeader()');
            });
        } else {
          if (this.fileAttached) {
            this.saveImage();
          } else {
            // this.notiService.showSuccess('Option Updated');
            // this.close({ option: this.updatedOption });
            this.addOptionListHouseTypes();
          }
        }
      },
      err => {
        this.genericError(err, 'editOption()');
      }
    );
  }

  setUpdatedEditOption() {
    this.setBaseUpdatedOption();
    if (this.isHeader) {
      this.updatedOption.notSelectable = true;
      this.updatedOption.optionTypeId = OptionTypeEnum.Standard;
    }

    /* If not dummy null house type, use the value or set to null */
    const houseTypeId = +this.optionForm.get('houseTypeId').value;
    this.updatedOption.houseTypeId = houseTypeId !== -1 ? houseTypeId : null;

    if (this.isHouseOption) {
      this.updatedOption.notSelectable = this.optionForm.get('notSelectable').value;
    }

    if (this.newLibImgId) {
      this.updatedOption.optionImageId = this.newLibImgId;
      this.updatedOption.attachmentId = null;
    } else if (this.haveLibraryImage) {
      this.updatedOption.optionImageId = this.option.optionImageId;
    }
    if (this.removingLibImg) {
      this.updatedOption.optionImageId = null;
    }
  }

  delete() {
    if (!this.confirmingDelete) {
      this.confirmingDelete = true;
    } else {
      this.optionForm.patchValue({
        mode: 'delete'
      });
      this.submitLoading = true;
      this.optionForm.disable();
      if (!this.isHeader && this.optionListAttachment) {
        this.deleteImage();
      } else {
        this.deleteOption();
      }
    }
  }

  onDeleteChildrenChanged() {
    this.deleteChildren = !this.deleteChildren;
  }

  cancelDelete() {
    this.confirmingDelete = false;
  }

  deleteImageSelected() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, { windowClass: 'modal-middle' });
    modalRef.componentInstance.confirmationText = 'Do you wish to delete the attached image?';

    modalRef.result.then((confirmed) => {
      if (confirmed) {
        this.deleteImgSelected = true;
        this.scrollImgSelectorIntoView();
        this.deleteImage();
      }
    }, (reason) => {
    });
  }

  deleteImage() {
    const imgSub = this.isHeader ?
      this.headerService.deleteCurrentHeaderImage() :
      this._optionListAttachmentService.deleteOptionListAttachment(this.option.id);
    imgSub.subscribe(
      res => {
        this.optionListAttachment = null;
        this.imageFound = false;
        this.notiService.showSuccess('Image deleted');
        if (this.deleteImgSelected) {
          this.modalEvent.emit({
            option: this.option,
            deleteImage: true
          });
        } else {
          this.deleteOption();
        }
      },
      err => {
        this.genericError(err, this.isHeader ? 'deleteImage() - header' : 'deleteImage()');
      });
  }

  deleteOption() {
    if (this.isHeader) {
      // even if the option is not a header, the header record will exist until the option is deleted
      this.headerService.deleteHeaderIfExists(this.option.id.toString());
    }

    this._optionListService.deleteOption(this.option.id, this.deleteChildren)
      .subscribe(() => {
        this.deleteChildren = false;
        this.notiService.showSuccess('Option Deleted');
        this.close({
          option: this.updatedOption,
          // forceRefresh: true,
          delete: true
        });
      },
        err => this.genericError(err, this.isHeader ? 'deleteHeader()' : 'deleteOption()')
      );
  }

  imageCropped(event: ImageCroppedEvent) {
    this.fileToUpload = event.base64;
    this.scrollImgSelectorIntoView();
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  findImage() {
    this.imageFound = false;
    this.modalImageLoading = true;

    if (this.isHeader) {
      this.headerService.fetchImage()
        .subscribe(
          optionListAttachment => {
            if (optionListAttachment) {
              this.findImageSuccess(optionListAttachment);
            } else {
              this.modalImageLoading = false;
            }
          },
          err => {
            this.findImageError(err);
          }
        );
    } else {
      this._optionListAttachmentService.getOptionListAttachment(this.option.id)
        .subscribe(
          optionListAttachment => {
            this.findImageSuccess(optionListAttachment);
          },
          err => {
            this.findImageError(err);
          }
        );
    }
  }

  /* Save the img file, deleting prev img first if one has been found */
  saveImage() {
    if (this.fileType === 'pdf') {
      const formData = new FormData();
      // for (const file of this.fileUploader.value) {
      formData.append(this.imageFile.name, this.imageFile);
      // }
      this.postImage(formData);
    } else {
      this.imgService.compressImage(this.fileToUpload).subscribe(compressedImageFile => {
        if (this.optionListAttachment) {
          // to get the tree to refesh we delete then post so the attachment id changes
          this._optionListAttachmentService.deleteOptionListAttachment(this.option.id).subscribe(
            () => {
              this.postImage(compressedImageFile);
            }, err => {
              this.genericError(err, 'deleteOptionListAttachment()', 'There was an error deleting the existing image. Please try again in a moment.');
            });
        } else {
          this.postImage(compressedImageFile);
        }
      }, err => {
        this.saveImageError(err);
      });
    }
  }

  postImage(imageFile: FormData) {
    this._optionListAttachmentService.postOptionListAttachment(this.updatedOption.id, imageFile).subscribe(() => {
      this.updatedOption.attachmentId = this.updatedOption.id;
      this.addOptionListHouseTypes();
    }, err => {
      this.saveImageError(err);
    });
  }

  // imageSuccess() {
  //   if (this.mode === OptionModalMode.add) {
  //     this.addOptionListHouseTypes();
  //   } else {
  //     this.notiService.showSuccess('Option Updated');
  //     this.close({ option: this.updatedOption });
  //   }
  // }

  finishAddUpdate() {
    if (this.mode === OptionModalMode.add) {
      this.notiService.showSuccess('Option Added');
      if (this.addingAnother) {
        this.finishAddingAnother();
      } else {
        this.modalEvent.emit({
          option: this.updatedOption,
          addingAsChild: this.optionAddType === OptionAddType.child
        });
        this.close(null);
      }
    } else {
      this.notiService.showSuccess('Option Updated');
      this.close({ option: this.updatedOption });
    }
  }

  finishAddingAnother() {
    this.modalEvent.emit({
      option: this.updatedOption,
      addingAsChild: this.optionAddType === OptionAddType.child
    });


    this.fileToUpload = null;
    this.imageFound = false;
    this.optionListAttachment = null;
    this.haveLibraryImage = false;
    this.newLibImgId = null;
    this.addedOptionOrderNo = this.updatedOption.orderNo + 1;
    this.selectedHouseTypeIds = [];

    this.addSetup();
    this.optionAddType = OptionAddType.sibling;

    this.processHouseOptions();

    this.submitLoading = false;
    this.descInput.nativeElement.focus();
  }

  findImageSuccess(attachment) {
    this.optionListAttachment = attachment;
    this.imageFound = true;
    this.modalImageLoading = false;
  }

  findImageError(err) {
    this.imageFound = false;
    this.modalImageLoading = false;
    this.optionListAttachment = null;
    this.notiService.showWarning('There was an error finding the image. Please try again in a moment');
    this.logService.log(this.COMPONENT_NAME, this.isHeader ? 'findImage() - header' : 'findImage()', err, 'Error with getOptionListAttachment()');
  }

  saveImageError(err) {
    this.imageFound = false;
    this.submitLoading = false;
    this.optionForm.enable();
    this.notiService.showError('There was an error saving the image. Please try again in a moment.');
    this.logService.log(this.COMPONENT_NAME, 'saveImage()', err, 'Error saving img');
  }

  genericError(err, method?: string, message?: string) {
    this.submitLoading = false;
    this.optionForm.enable();
    if (message) {
      this.notiService.showError(message);
    } else {
      this.notiService.notify(err);
    }
    this.logService.log(this.COMPONENT_NAME, method, err);
  }

  setBaseUpdatedOption() {
    this.updatedOption = {
      id: this.option ? this.option.id : null,
      orderNo: this.option ? this.option.orderNo : null,
      optionListIdAbove: this.option ? this.option.optionListIdAbove : null,
      children: null,
      description: this.optionForm.get('description').value.trim(),
      printedDescription: this.optionForm.get('printedDesc').value === null ? '' : this.optionForm.get('printedDesc').value.trim(),
      defaultDescription: this.optionForm.get('itemPrefix').value,
      productCode: this.optionForm.get('productCode').value,
      warningNote: this.optionForm.get('warningNote').value,
      childSelectionWarning: this.optionForm.get('childSelectionWarning').value,
      notSelectable: this.optionForm.get('optionType').value === OptionTypeEnum.Header ? true : false,
      isBoldText: this.optionForm.get('isBoldText').value,
      isActive: this.optionForm.get('isActive').value,
      optionColour: +this.optionForm.get('optionColour').value,
      optionTypeId: this.optionForm.get('optionType').value,
      houseTypeId: this.optionForm.get('houseTypeId').value,
      optionImageId: null,
      isQtyRequired: this.optionForm.get('qtyRequired').value,
      hideQuantity: this.optionForm.get('hideQuantity').value,
      childItemSelectableByClient: this.optionForm.get('clientChildren').value,
      salesPriceIfAdded: +this.optionForm.get('salesPriceAdded').value,
      salesPriceTypeIfAddedId: +this.optionForm.get('salesPriceAddedId').value,
      salesPriceIfChangedInSameList: +this.optionForm.get('salesPriceChanged').value,
      salesPriceTypeIfChangedInSameListId: +this.optionForm.get('salesPriceChangedId').value,
      costToCompanyIfAdded: +this.optionForm.get('costChanged').value,
      costToCompanyIfChangedInSameList: +this.optionForm.get('costAdded').value,
      priceIfAddedRecipeId: this.optionForm.get('addedRecipe').value,
      priceIfChangedRecipeId: this.optionForm.get('changedRecipe').value,
      attachmentId: this.mode === OptionModalMode.edit && this.option.attachmentId ? this.option.attachmentId : null,
      attachmentName: this.mode === OptionModalMode.edit && this.option.attachmentName ? this.option.attachmentName : null,
      attachmentTypeId: this.mode === OptionModalMode.edit && this.option.attachmentTypeId ? this.option.attachmentTypeId : null,
      optionListCategories: null, lastPriceUpdate: null, lastPriceUpdatedByUserId: null,
      imageNotPrinted: this.optionForm.get('imageNotPrinted').value,
      level1Name: '',
      level2Name: '',
      level3Name: '',
      level4Name: ''
    };
  }

  selectAnotherImg() {
    this.imageFound = !this.imageFound;
    this.scrollImgSelectorIntoView();
  }

  scrollImgSelectorIntoView() {
    this.optionForm.disable();
    setTimeout(() => {
      this.ngxImgScroll.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setTimeout(() => {
        this.optionForm.enable();
      }, 400);
    }, 10);
  }

  addCategories() {
    this.lighten = true;
    const modalRef = this.modalService.open(OptionCategoryModalComponent);
    modalRef.componentInstance.option = this.option;

    modalRef.result.then(() => {
      this.lighten = false;
    }, (reason) => {
      this.lighten = false;
    });
  }

  getNullHouseType(t: string) {
    if (t === 'root') {
      return <HouseType>{
        id: -1,
        description: this.isHouseOption ? 'Generic' : 'All',
        isActive: false,
        jobId: null,
        salesPrice: null,
        costToCompany: null,
        lastPriceUpdateDate: null,
        productCode: null
      };
    } else if (t === 'child') {
      return <HouseType>{
        id: -1,
        description: this.isHouseOption ? 'Inherit' : 'All',
        isActive: false,
        jobId: null,
        salesPrice: null,
        costToCompany: null,
        lastPriceUpdateDate: null,
        productCode: null
      };
    }
  }

  // getHouseTypeObject(id): HouseType {
  //   for (const currType of this.houseTypes) {
  //     if (currType.id === id) {
  //       return currType;
  //     }
  //   }
  //   return null;
  // }

  recipeSearch(term: string, item: Recipe) {
    term = term.toLocaleLowerCase();
    return item.recipeCode.toLocaleLowerCase().indexOf(term) > -1 ||
      item.description.toLocaleLowerCase().indexOf(term) > -1;
  }

  recipeChanged(added: boolean, dropDownBox, e) {
    if (e.selectedRowKeys.length > 0) {
      const recipe = this.recipes.find(i => i.id === e.selectedRowKeys[0]);

      const costingDate = new Date();
      const currentCostingDateString = costingDate.getFullYear() + '-'
        + ('0' + (costingDate.getMonth() + 1)).toString().slice(-2) + '-'
        + ('0' + costingDate.getDate()).slice(-2);

      const recipeSelling = this.estimatingService.recipeSellingCache
        .find(i => i.recipeId === recipe.id
          && i.districtId === recipe.districtId
          && i.effectiveDate.toString().slice(0, 10) <= currentCostingDateString);

      if (added) {
        this.optionForm.get('addedRecipe').setValue(e.selectedRowKeys[0]);
        this.optionForm.get('salesPriceAdded').setValue(recipeSelling ? recipeSelling.rate : null);
        this.optionForm.get('costAdded').setValue(recipeSelling ? recipeSelling.costPrice : null);
      } else {
        this.optionForm.get('changedRecipe').setValue(e.selectedRowKeys[0]);
        this.optionForm.get('salesPriceChanged').setValue(recipeSelling ? recipeSelling.rate : null);
        this.optionForm.get('costChanged').setValue(recipeSelling ? recipeSelling.costPrice : null);
      }

      dropDownBox.close();
    }
  }

  close(msg: OptionModalReturnType) {
    this.activeModal.close(msg);
  }

  setDescToNotApplicable() {
    this.optionForm.patchValue({
      description: 'Not Applicable'
    });
  }

  refreshRecipes() {
    this.refreshingRecipes = true;

    this.subscriptions.push(
      this.estimatingService.getRecipeWithSellingRates(false).subscribe(recipes => {
        this.recipes = recipes.filter(i => i.recipeTypeId === RecipeTypeEnum.Recipe);
        this.refreshingRecipes = false;
      }, () => {
        this.refreshingRecipes = false;
      })
    );
  }

  downloadImage() {
    // download the image
    const buffers = this.imgService.base64ToArrayBuffer(this.optionListAttachment.attachment);
    saveAs(buffers[1], this.optionListAttachment.attachmentName);
  }

  addFromRecipes() {
    const modalRef = this.modalService.open(RecipeSearchComponent, { windowClass: 'modal-1000' });
    modalRef.componentInstance.option = this.option;
    modalRef.componentInstance.selectMultiple = true;

    modalRef.result.then((recipeIds) => {
      if (recipeIds && recipeIds.length) {
        this.loading = true;

        this.subscriptions.push(
          this._optionListService.addOptionsFromRecipes(this.option.id, recipeIds).subscribe(() => {
            this.loading = false;
            this.close({
              option: this.updatedOption,
              refresh: true,
              delete: false
            });
          }, err => {
            this.loading = false;
            this.notiService.notify(err);
          })
        );
      }
    }, () => { });
  }

  fileSelected(e) {
    if (e.value.length === 1) {
      this.fileType = e.value[0].name.substr(e.value[0].name.lastIndexOf('.') + 1);
      this.fileType = this.fileType.toLowerCase();
      if ((this.fileType === 'pdf' && this.isHouseOption) || this.fileType === 'jpg' || this.fileType === 'jpeg' || this.fileType === 'png') {
        this.wrongType = false;
        this.haveLibraryImage = false;
        this.removingLibImg = false;
        this.fileAttached = true;
        this.newLibImgId = null;
        this.imageFile = e.value[0];
        if (this.fileType !== 'pdf') {
          this.imageFileLoaded = true;
        } else {
          this.imageFileLoaded = false;
        }
      } else {
        this.wrongType = true;
        this.fileUploader.value = null;
      }
    }
  }


  addOptionListHouseTypes() {
    // check the lists are the same
    let same = true;
    this.selectedHouseTypeIds.forEach(id => {
      if (!this.originalSelectedHouseTypeIds.includes(id)) {
        same = false;
      }
    });
    this.originalSelectedHouseTypeIds.forEach(id => {
      if (!this.selectedHouseTypeIds.includes(id)) {
        same = false;
      }
    });

    if (same) {
      this.finishAddUpdate();
    } else {
      this._optionListService.updateOptionListHouseTypes(this.optionListId, this.selectedHouseTypeIds).subscribe(
        res => {
          this.finishAddUpdate();
        },
        err => {
          this.notiService.notify(err);
        }
      );
    }
  }
}
