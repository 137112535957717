export const APP_NAME = 'TruthEngineConfig';
export const APP_ID = 2;

export const MAJOR_VERSION = '2.7';
export const PATCH_VERSION = '.4b'; // not stored in DB or checked in refresh guard

export const SUBHEADER_SMALL_WIDTH = 500; // pixel width of window to switch to small mode

export const MAX_OPTION_IMAGE_SIZE_MB = 0.075; // Uploaded imgs compressed to this size - GH 29-3 - decrease to max 50k

/// B2C / MSAL ///
export const B2C_AUTHORITY = 'https://truthengine.b2clogin.com/truthengine.onmicrosoft.com/B2C_1_signup-signin-prod';
export const B2C_AUTHORITY_DEV = 'https://truthengine.b2clogin.com/truthengine.onmicrosoft.com/B2C_1_signup-signin-dev';
export const B2C_AUTHORITY_DOMAIN = 'truthengine.b2clogin.com';

/// CLIENT DASHBOARD ///
export const OPTION_AND_WELCOME_HEADER_MAX_HTML_LEN = 10000;

export const DEFAULT_HEADER_HTML_BACKGROUND_COLOR = '#e3f2fd';

export const baseHTMLText = '<span style="color: orange"><b>REPLACE THIS AND THE BELOW TEXT TO USE CUSTOM FORMATTING FOR YOUR HEADER</b></span> <br><br>';
export const HTML_STYLE_DEFAULT_ABOVE = baseHTMLText + 'This styling will appear <b>above</b> your attached Image. <br>If you are not attaching an image then this is the only section required.'
    + '<br><br> If you do not change any of the custom style sections from the defaults then the <b>Description Field</b> will be used with basic formatting.';
export const HTML_STYLE_DEFAULT_BESIDE = baseHTMLText + 'This styling will appear to the <b>right</b> of your attached Image.';
export const HTML_STYLE_DEFAULT_BELOW = baseHTMLText + 'This styling will appear <b>below</b> your attached Image.';
