import { Component, Input, OnInit, OnChanges, ViewChild, ChangeDetectorRef, OnDestroy, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TreeComponent, TREE_ACTIONS, TreeNode } from '@circlon/angular-tree-component';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { IOptionList } from './types/option-list';
import { OptionModalComponent } from './option-modal/option-modal.component';
import { OptionColourEnum } from './types/option-list';

import { OptionListService } from './services/option-list.service';
import { GlobalService } from '../services/global.service';
import { OptionListAttachmentService } from './services/option-list-attachment.service';
import { HouseTypeApiService } from '../maintenance/house-types/services/house-type.api.service';
import { NotificationService } from '../services/notification.service';
import { OptionModalEventType, OptionModalReturnType, OptionModalMode } from './types/option-list-misc.type';
import { LogService } from '../services/log.service';
import { FileProcessingService } from '../services/file-processing.service';
import { CopyChildrenComponent } from './copy-children/copy-children.component';
import { AttachmentTypeEnum } from '../dtos/attachment-type.enum';


@Component({
  selector: 'js-option-lists',
  templateUrl: './option-lists.component.html',
  styleUrls: ['./option-lists.component.scss']
})
export class OptionListsComponent implements OnInit, OnChanges, OnDestroy {

  // optionPressed: number; // temp hack see optionSelected()
  // optionList: IOptionList[] = []; // points to cache list in service

  COMPONENT_NAME = 'option-lists';

  @Input() ShowImages: boolean;

  @ViewChild('tree') tree: TreeComponent;

  filterText: string;
  searching = false;

  options: IOptionList[];
  treeOptionNodes: IOptionList[] = []; // options after filtering via standard or house types. linked to tree component
  treeOptionOptions = {};

  optionColourEnum = OptionColourEnum;
  copyNodeID: number;
  filterNode: number;

  closeResult: string;
  panelName: string;
  smallWidth;
  medWidth;
  innerWidth: number;
  optDescLength: number;
  subscriptions: Subscription[] = [];

  private searchDebounceSubject: Subject<string> = new Subject();
  private searchDebounceTimeMillis = 500;

  noOptions = false;
  houseOptionMode = false;
  hasChildrenBelow = false;
  loading = true;
  attachmentTypeEnum = AttachmentTypeEnum;

  constructor(
    private _optionListService: OptionListService,
    private globalService: GlobalService,
    private logService: LogService,
    private imgService: FileProcessingService,
    private cd: ChangeDetectorRef,
    private optionListAttachmentService: OptionListAttachmentService,
    private modalService: NgbModal,
    private notiService: NotificationService,
    private houseTypeService: HouseTypeApiService
  ) {
    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe(width => {
        this.innerWidth = width;
        this.setDescLength();
      }),
      this._optionListService.refreshOptionListEvent.subscribe(refresh => {
        this.refreshNodes();
      })
    );
  }

  ngOnInit() {
    this.medWidth = this._optionListService.mediumHeadersThreshold;
    this.smallWidth = this._optionListService.smallHeadersThreshold;
    this.innerWidth = this.globalService.innerWidth;

    this.setDescLength();

    this.setupOptionNodes();
    this.refreshNodes();

    this.searchDebounceSubject.pipe(
      debounceTime(this.searchDebounceTimeMillis)
    ).subscribe(searchTextValue => {
      this.handleSearch(searchTextValue);
    });
  }

  /* Refresh tree when showImages is updated from option-list-start */
  ngOnChanges() {
    if (this.tree) {
      this.tree.treeModel.update();
    }
  }

  setupOptionNodes() {
    this.treeOptionOptions = {
      idField: 'id',
      displayField: 'description',
      childrenField: 'children',
      // useVirtualScroll: true,
      nodeHeight: 22,
      allowDrag: true,
      allowDrop: true,
      /* Override clone method to keep prevous ID for use in copying attachment
         Temp hardcoded id (-1) used to delete copied node from tree on error   */
      getNodeClone: (node) => ({
        ...node.data,
        id: -1,
        oldID: node.id
      }),
      actionMapping: {
        mouse: {
          click: (tree, node, $event) => {
            if (node.hasChildren) {
              TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
            }
          }
        }
      }
    };
  }

  /* Get fresh list of options (replace cache) */
  refreshNodes(loadSpin = true) {
    if (loadSpin) {
      this.loading = true;
    }
    this._optionListService.clearOptionsCache();
    this._optionListService.getOptionListCached()
      .subscribe(
        optionList => {
          this.options = optionList;

          if (optionList.length === 0) {
            this.noOptions = true;
          } else {
            this.noOptions = false;
          }

          this.filterNodeType();
          if (this.tree) {
            this.tree.treeModel.update();
          }

          setTimeout(() => {
            // set up nodes for search
            // this.treeService.setPathString(this.tree);
            // re-filter
            if (this.filterText && this.filterText.length > 2) {
              this.handleSearch(this.filterText);
            } else if (this.filterText && this.filterText.length > 0 && this.filterText.length < 3) {
              this.filterText = '';
            }
          }, 100);

          this.loading = false;
        },
        error => {
          this.loading = false;
          this.notiService.notify(error);
          this.logService.log(this.COMPONENT_NAME, 'refreshNodes()', error, 'Error refreshing option-list');
        });
  }

  modeChanged(mode: string) {
    if (mode === 'generic') {
      this.houseOptionMode = false;
    } else if (mode === 'house') {
      this.houseTypeService.getHouseTypesCached(true); // pre-emptively cache types
      this.houseOptionMode = true;
    }
    this.filterNodeType();
  }

  filterNodeType() {
    if (this.houseOptionMode) {
      this.treeOptionNodes = this.options.filter(option => {
        return option.optionTypeId === 5;
      });
    } else {
      this.treeOptionNodes = this.options.filter(option => {
        return option.optionTypeId !== 5;
      });
      if (this.tree) {
        this.tree.treeModel.update();
      }
    }
  }

  onMoveNode($event, tree) {
    const movedNodeId = $event.node.id;
    // let newIndex = $event.to.index + 1; // tree uses 0-based indexing, back-end uses 1
    let parentId = null;
    if ($event.to.parent.virtual || !$event.to.parent) {
      parentId = null;
    } else {
      parentId = $event.to.parent.id;
    }

    const newIndex = this.correctIndexForHouseOptions(parentId, $event.to.index);

    this.moveOption(movedNodeId, newIndex, parentId);
  }

  onCopyNode($event) {
    const movedNode = $event.node;
    // let newIndex = $event.to.index + 1; // tree uses 0-based indexing, back-end uses 1
    let parentId = null;
    if (!$event.to.parent || $event.to.parent.virtual) {
      parentId = null;
    } else {
      parentId = $event.to.parent.id;
    }

    const newIndex = this.correctIndexForHouseOptions(parentId, $event.to.index);

    movedNode.orderNo = newIndex;
    movedNode.optionListIdAbove = parentId;

    if (movedNode.attachmentId) {
      this.getAttachment(movedNode);
    } else {
      if (movedNode.children && movedNode.children.length) {
        // do we copy the children also
        const modalRef = this.modalService.open(CopyChildrenComponent, { backdrop: 'static' });

        modalRef.result.then((result: boolean) => {
          if (result) {
            this.copyOption(movedNode, null, true);
          } else {
            this.copyOption(movedNode, null, false);
          }
        }, () => {
        });
      } else {
        this.copyOption(movedNode, null, false);
      }
    }
  }

  correctIndexForHouseOptions(parentId: number, index: number): number {
    let newIndex = index + 1;
    if (parentId === null && index !== 0) {
      // we have standard or house options so we need to adjust
      let i = 0;
      this.treeOptionNodes.forEach(element => {
        if (element.optionListIdAbove === null
          && ((this.houseOptionMode && element.optionTypeId === 5) || (!this.houseOptionMode && element.optionTypeId !== 5))
          && i < index) {
          // console.log('treeOptionNodes node ' + JSON.stringify(element));
          newIndex = element.orderNo + 1;
          i++;
        }
      });
    }

    return newIndex;
  }

  /* Launches modal in edit mode. Is returned an updated form */
  editItem(node: TreeNode) {
    this.subscriptions.push(
      this._optionListService.getOption(node.data.id).subscribe(
        optionRes => {
          node.data = { ...optionRes }; // update the option
          this.openEditModal(node);
        },
        err => {
          this.notiService.showError(err);
        }
      )
    );
  }

  openEditModal(node: TreeNode) {
    const modalRef = this.modalService.open(OptionModalComponent, { backdrop: 'static', windowClass: 'modal-1000' });
    modalRef.componentInstance.modalHeading = 'Edit Option';
    modalRef.componentInstance.mode = OptionModalMode.edit;
    modalRef.componentInstance.parent = node.parent;
    modalRef.componentInstance.option = node.data;
    modalRef.componentInstance.isRoot = node.isRoot;
    modalRef.componentInstance.isHouseOption = this.houseOptionMode;

    // update deleted img before modal closes as img deletion is applied immediately
    modalRef.componentInstance.modalEvent.subscribe((res: OptionModalEventType) => {
      if (res && res.deleteImage) {
        node.data.attachmentId = null;
      }
      if (res && res.forceRefresh) {
        this.refreshNodes();
      }
    });

    modalRef.result.then((result: OptionModalReturnType) => {
      if (result) {
        if (result.refresh) {
          this.refreshNodes();
        } else if (result.delete) {
          this.deleteTreeNode(node.data.id, this.options);
          this.filterNodeType();
          this.tree.treeModel.update();
        } else if (result.option) {
          // result.option.path_string = this.treeService.pathString(node, result.option.description + ', ');
          this.editTreeNode(result.option, this.options);
          this.filterNodeType();
          this.tree.treeModel.update();
          // node.data = {...result.option}; // can do this but have to refresh list when option type changed
        }
      }
    }, (reason) => {
    });
  }

  /* Launches add-item modal. Updates node in tree to match changes on return */
  addItemSelected(node: TreeNode) {
    const modalRef = this.modalService.open(OptionModalComponent, { backdrop: 'static', windowClass: 'modal-1000' });

    modalRef.componentInstance.mode = OptionModalMode.add;
    modalRef.componentInstance.option = node ? node.data : null;
    // const parentCpy = {...node.parent};
    modalRef.componentInstance.parent = node ? node.parent : null;
    modalRef.componentInstance.isRoot = node ? node.isRoot : true;
    modalRef.componentInstance.isHouseOption = this.houseOptionMode;

    // update list when option added
    // we may add multiple so we start with the current id
    let currentNodeId = node ? node.data.id : null;

    modalRef.componentInstance.modalEvent.subscribe((res: OptionModalEventType) => {
      if (res && res.forceRefresh) {
        this.refreshNodes();
      } else {
        if (!res.failed) {
          this.noOptions = false;
        }
        const newRecord = res.option;
        // const startPathStr = node && node.data.description ?
        //   node.data.description + ', ' + newRecord.description + ', '
        //   : newRecord.description + ', ';
        // newRecord.path_string = this.treeService.pathString(node, startPathStr);

        if (!node) {
          // adding base option so add new record directly
          this.options.push(newRecord);
        } else if (res.addingAsChild) {
          this.addTreeChild(currentNodeId, newRecord, this.options);
          // node.data.children.push(newRecord); // can do this but then need to refresh list when changing option type
        } else {
          // we can add new record directly underneath if a parent is shared (i.e. sibling)
          this.addTreeNodeInline(currentNodeId, newRecord, this.options);
          // node.parent.data.children.push(newRecord); // can do this but then need to refresh list when changing option type
        }
        this.filterNodeType();
        this.tree.treeModel.update();
        currentNodeId = newRecord.id;
      }
    });

    modalRef.result.then((result: OptionModalReturnType) => {
      if (result?.refresh) {
        this.refreshNodes();
      }
    }, () => {
    });
  }

  getAttachment(option) {
    this.optionListAttachmentService.getOptionListAttachment(option.attachmentId)
      .subscribe(
        attachment => {
          const formData = this.imgService.blobToFormData(this.imgService.imageDataURItoBlob(attachment.attachment));
          this.copyOption(option, formData, false);
        },
        err => {
          this.copyError(option, err);
        }
      );
  }

  copyOption(option, attachment, copyChildren: boolean) {
    this._optionListService.addOption(option).subscribe(
      optionRes => {
        if (copyChildren) {
          this.copyChildren(option.oldID, optionRes.id);
        } else if (attachment) {
          this.copyAttachment(optionRes, attachment);
        } else {
          this.refreshNodes(false);
        }
      },
      err => {
        this.copyError(option, err);
      }
    );
  }

  copyChildren(previousId: number, newId: number) {
    this._optionListService.copyOptionChildren(previousId, newId).subscribe(
      () => {
        this.refreshNodes(false);
      },
      err => {
        this.notiService.notify(err);
        this.refreshNodes(false);
      }
    );
  }

  copyAttachment(option, attachment) {
    this.optionListAttachmentService.postOptionListAttachment(option.id, attachment).subscribe(
      () => {
        this.refreshNodes(false);
      }, err => {
        this.copyError(option, err);
      }
    );
  }

  copyError(option, err) {
    this.deleteTreeNode(-1, this.treeOptionNodes); // use hardcoded temp copied option id
    this.tree.treeModel.update();
    this.notiService.notify(err);
    const logMsg = 'Error copying option ' + option.description + ' to parent ' + parent;
    this.logService.log(this.COMPONENT_NAME, 'moveOption()', err, logMsg);
  }

  moveOption(optionId: number, index: number, parent: number) {
    this._optionListService.moveOption(optionId, parent, index).subscribe(
      () => {
        this.refreshNodes(false);
      },
      err => {
        // hard to replace original position so reload tree from backend
        this.refreshNodes();
        this.notiService.notify(err);
        const logMsg = 'Error moving option #' + optionId + ' to parent ' + parent + ' to index ' + index;
        this.logService.log(this.COMPONENT_NAME, 'moveOption()', err, logMsg);
      }
    );
  }

  //////////////////////////   SEARCH   ///////////////////////////////
  searchKeyup(searchString: string) {
    this.searchDebounceSubject.next(searchString);
  }

  handleSearch(searchString: string) {
    if (searchString === '') {
      this.tree.treeModel.clearFilter();
      this.tree.treeModel.collapseAll();
    } else if (searchString.length > 2) {
      this.searching = true;
      setTimeout(() => {
        // this.cd.detectChanges();
        // this.tree.treeModel.filterNodes((node: TreeNode) => this.treeService.parentStringSearch(searchString, node), false);
        this.tree.treeModel.filterNodes(searchString, true);
        // this.treeService.expandExactMatches(searchString, this.tree.treeModel);
        this.searching = false;
      }, 10);
    }
  }

  clearSearch($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.filterText = '';
    this.tree.treeModel.clearFilter();
    this.tree.treeModel.collapseAll();
  }
  ////////////////////////////////////////////////////////////////////////

  deleteTreeNode(nodeID, list: any[]) {
    let i = 0;
    if (!list) {
      return;
    }
    for (i; i < list.length; i++) {
      if (list[i].id === nodeID) {
        list = list.splice(i, 1);
        return;
      } else {
        this.deleteTreeNode(nodeID, list[i].children);
      }
    }
  }

  editTreeNode(node: IOptionList, list: any[]) {
    let i = 0;
    if (!list) {
      return;
    }
    for (i; i < list.length; i++) {
      if (list[i].id === node.id) {
        IOptionList.copyOptionTreeNodeIntoOption(list[i], node);
        return;
      } else {
        this.editTreeNode(node, list[i].children);
      }
    }
  }


  addTreeNodeInline(currentNodeId: number, newNode: IOptionList, list: IOptionList[]) {
    for (let i = 0; i < list.length; i++) {
      const element = list[i];

      // insert new node below current node
      if (element.optionListIdAbove === newNode.optionListIdAbove && element.id === currentNodeId) {
        list.splice(i + 1, 0, newNode);
        break;
      } else if (element.children) {
        this.addTreeNodeInline(currentNodeId, newNode, element.children);
      }
    }
  }

  addTreeChild(currentNodeId: number, newNode: IOptionList, list: IOptionList[]) {
    for (let i = 0; i < list.length; i++) {
      const element = list[i];

      if (element.id === currentNodeId) { // insert as child
        element.children ? element.children.push(newNode) : element.children = [newNode];
        break;
      } else if (element.children) {
        this.addTreeChild(currentNodeId, newNode, element.children);
      }
    }
  }

  treeNodeClicked(e) {
    if (e.isExpanded) {
      const node: TreeNode = e.node;
      node.children.forEach(c => c.show());
    }
  }

  setDescLength() {
    this.optDescLength = Math.floor(this.innerWidth / 12);
    if (this.innerWidth < this.medWidth) {
      this.optDescLength = this.optDescLength - 5;
    }
    if (this.innerWidth < this.smallWidth) {
      this.optDescLength = this.optDescLength - 5;
    }
  }

  ngOnDestroy() {
    this.houseTypeService.clearCaches();
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

}

